import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

export const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`

interface WrapperProps {
  color?: string
}

export const Wrapper = styled.div<WrapperProps>(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(0.6)',
    width: '70px',

    '> div': {
      animation: `${bounceDelay} 1.4s infinite ease-in-out both`,
      borderRadius: '100%',
      flex: 1,
      height: '18px',
      margin: '0 2px',
      width: '18px',

      '&:first-of-type': {
        animationDelay: '-0.32s',
      },

      '&:nth-of-type(2)': {
        animationDelay: '-0.16s',
      },
    },
  },
  ({ theme, color }) => ({
    '> div': {
      background: color || theme.colors.primary,
    },
  }),
)
