import Image from 'next/image'
import React, { useState } from 'react'

import { TooltipClosed, TooltipOpened } from '@lib/analytics'

import {
  InfoIconContainer,
  InfoIconWrapper,
  Tooltip,
  TooltipTitle,
} from './style'

interface InfoTooltipProps {
  anchor?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  children: React.ReactNode
  iconHeight?: number
  title: string
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  anchor = 'bottom-right',
  children,
  iconHeight = 20,
  title,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const deriveAnchorStyling = () => {
    switch (anchor) {
      case 'top-left':
        return {
          bottom: 0,
          right: iconHeight,
        }
      case 'top-right':
        return {
          bottom: 0,
          left: iconHeight,
        }
      case 'bottom-left':
        return {
          top: 0,
          right: iconHeight,
        }
      case 'bottom-right':
        return {
          top: 0,
          left: iconHeight,
        }
    }
  }

  const handleMouseEnter = () => {
    // Show the tooltip.
    setShowTooltip(true)

    // Track the event.
    analytics.track(TooltipOpened, {
      title,
    })
  }

  const handleMouseLeave = () => {
    // Hide the tooltip.
    setShowTooltip(false)

    // Track the event.
    analytics.track(TooltipClosed, {
      title,
    })
  }

  return (
    <InfoIconContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InfoIconWrapper style={{ height: iconHeight, width: iconHeight }}>
        <Image
          alt={title}
          height={iconHeight}
          src="/icons/icon-info.svg"
          width={iconHeight}
        />
      </InfoIconWrapper>
      {showTooltip && (
        <Tooltip style={deriveAnchorStyling()}>
          <TooltipTitle>{title}</TooltipTitle>
          {children}
        </Tooltip>
      )}
    </InfoIconContainer>
  )
}

export default InfoTooltip
