// Relative imports.
import { Wrapper } from './style'

interface SpinnerProps {
  color?: string
}

const Spinner = (props: SpinnerProps) => (
  <Wrapper color={props.color} className="spinner">
    <div />
    <div />
    <div />
  </Wrapper>
)

export default Spinner
