import PageBreak from '@components/core/pageBreak'
import PageContent from '@components/core/pageContent'

import ClientsCreated from './clientsCreated'
import { MyDashboardHeading } from './style'
import TotalMpcOperations from './totalMpcOperations'
import TotalWallets from './totalWallets'

const MyDashboardComponent = (): JSX.Element => {
  return (
    <PageContent>
      <MyDashboardHeading id="page-home-title">Home</MyDashboardHeading>
      <PageBreak />
      <ClientsCreated />
      <TotalWallets />
      <TotalMpcOperations />
    </PageContent>
  )
}

export default MyDashboardComponent
