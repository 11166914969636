import styled from '@emotion/styled'

export const MyDashboardChartContainer = styled.div({
  position: 'relative',
  margin: '32px 0',
})

export const MyDashboardWrapper = styled.div({
  maxHeight: '100vh',
  overflowY: 'auto',
  padding: '40px 33px 20px',
})

export const MyDashboardHeading = styled.h1(
  {
    margin: '0 0 24px',
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const MyDashboardInfo = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
})

export const MyDashboardInfoColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const MyDashboardInfoColumnHeading = styled.span(
  {
    display: 'flex',
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const MyDashboardInfoTotal = styled.p(
  {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.7rem',
    fontWeight: 500,
    margin: 0,
  },
  ({ theme }) => ({
    color: theme.colors.text.headline,
  }),
)

export const MyDashboardInfoDiff = styled.p<DiffProps>(
  {
    fontSize: '1rem',
    fontWeight: 400,
    padding: '6px 10px',
    margin: '0 0 0 12px',
  },
  ({ isPositive = true, theme }) => ({
    background: isPositive
      ? theme.colors.backgrounds.green
      : theme.colors.backgrounds.red,
    borderRadius: theme.borderRadius.rounder,
    color: isPositive ? theme.colors.text.green : theme.colors.text.buttonRed,
  }),
)

export const MyDashboardFilters = styled.div(
  {
    alignItems: 'center',
    display: 'flex',

    '.dropdown': {
      marginLeft: '12px',
    },

    '.rs-picker-daterange': {
      marginLeft: '12px',
    },

    '.rs-picker-toggle': {
      border: 'none',
      cursor: 'pointer',
      paddingTop: '9.5px !important',
      paddingBottom: '9.5px !important',
    },

    '@media (max-width: 950px)': {
      alignItems: 'flex-start',
      gap: '10px',
      flexDirection: 'column',
    },
  },
  ({ theme }) => ({
    '.rs-picker-toggle, .rs-picker-toggle-textbox, .rs-picker-toggle-value': {
      backgroundColor: `${theme.colors.backgrounds.greyLight} !important`,
      borderRadius: `${theme.borderRadius.default} !important`,
      color: `${theme.colors.grey} !important`,
      cursor: 'pointer',

      '&:focus': {
        borderRadius: `${theme.borderRadius.default} !important`,
        color: `${theme.colors.grey} !important`,
      },
    },
  }),
)
