import styled from '@emotion/styled'

const PageContent = styled.div(
  {
    maxHeight: 'calc(100vh - 23.5px)',
    overflowY: 'auto',
    padding: '20px 33px',
    minHeight: 'calc(100vh - 50px)',

    '@media (max-width: 950px)': {
      maxHeight: 'inherit',
      padding: '20px',
    },
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark ? theme.colors.black : null,
  }),
)

export default PageContent
