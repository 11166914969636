import { NextPage } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'

import HomeComponent from '@components/home'
import Page from '@components/page'

const HomePage: NextPage = () => {
  useEffect(() => {
    analytics.page('Insights')
  }, [])

  return (
    <Page isAuthed isDashboard>
      <Head>
        <title>Portal | Dashboard</title>
      </Head>

      <HomeComponent />
    </Page>
  )
}

export default HomePage
