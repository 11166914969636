import Spinner from '@components/core/spinner'

import { LoadingChartBackground, LoadingChartContent, Wrapper } from './style'

const LoadingChart = (): JSX.Element => {
  return (
    <Wrapper>
      <LoadingChartBackground />
      <LoadingChartContent>
        <p>Crunching the latest data just for you, hang tight...</p>
        <Spinner />
      </LoadingChartContent>
    </Wrapper>
  )
}

export default LoadingChart
