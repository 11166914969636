import styled from '@emotion/styled'

export const ListItems = styled.div(
  {
    boxShadow: '0px 12px 27px rgba(16, 24, 40, 0.07)',
    display: 'flex',
    flexDirection: 'column',
  },
  ({ theme }) => ({
    background: theme.isDark
      ? theme.colors.backgrounds.greyLight
      : theme.colors.white,
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.borderRadius.default,
    outline: 'none',
  }),
)

export const ListItem = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    padding: '8px 12px',
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.border.light}`,
  }),
)

export const ListItemCircle = styled.div(
  {
    height: '8px',
    marginRight: '8px',
    width: '8px',
  },
  ({ theme }) => ({
    background: theme.isDark
      ? `${theme.colors.white} !important`
      : theme.colors.grey,
    borderRadius: theme.borderRadius.circle,
  }),
)

export const ListItemValue = styled.span(
  {
    fontSize: '0.75rem',
    marginRight: '32px',
  },
  ({ theme }) => ({
    color: theme.isDark ? theme.colors.white : theme.colors.text.body,
  }),
)

export const ListItemLabel = styled.span(
  {
    fontSize: '0.75rem',
  },
  ({ theme }) => ({
    color: theme.isDark ? theme.colors.white : theme.colors.text.body,
  }),
)
