export const deriveUTCDate = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset() // get the time zone offset in minutes
  const sign = timezoneOffset > 0 ? '-' : '+' // check if the offset is positive or negative
  const absOffset = Math.abs(timezoneOffset) // get the absolute value of the offset
  const hoursOffset = Math.floor(absOffset / 60) // get the hours offset
  const minutesOffset = absOffset % 60 // get the minutes offset

  const dateUTC = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours() - (sign === '-' ? -hoursOffset : hoursOffset),
    date.getMinutes() - (sign === '-' ? -minutesOffset : minutesOffset),
    date.getSeconds(),
    date.getMilliseconds(),
  )

  return dateUTC
}
