import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Wrapper = styled.div(
  {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: '0 20px',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  ({ theme }) => ({
    borderRadius: theme.borderRadius.default,
  }),
)

export const LoadingChartBackground = styled.div({
  backdropFilter: 'blur(17px)',
  background: 'rgba(255, 255, 255, 0.03)',
  height: '100%',
  mixBlendMode: 'normal',
  position: 'absolute',
  width: '100%',
})

export const LoadingChartContent = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    position: 'relative',
    textAlign: 'center',

    p: {
      animation: `${fadeIn} 1s ease-in-out forwards`,
      animationDelay: '1.5s',
      margin: '0 0 12px',
      opacity: 0,
    },
  },
  ({ theme }) => ({
    borderRadius: theme.borderRadius.default,
  }),
)
