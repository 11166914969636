import styled from '@emotion/styled'

const PageBreak = styled.hr(
  {
    border: 'none',
    margin: '0 0 24px',
  },
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.border.default}`,
  }),
)

export const PageBreakSmall = styled(PageBreak)({
  alignSelf: 'center',
  margin: '0 0 20px',
  width: '50px',
})

export default PageBreak
