import { useTheme } from '@emotion/react'
import * as Sentry from '@sentry/nextjs'
import moment from 'moment'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import DateRangePicker, { RangeType } from 'rsuite/DateRangePicker'

import Dropdown from '@components/core/forms/dropdown'
import LoadingChart from '@components/core/loadingChart'
import GraphTooltip from '@components/graphTooltip'
import { useEnvironment } from '@context/environment'
import api from '@lib/api'
import { deriveUTCDate } from '@lib/time'

import {
  MyDashboardChartContainer,
  MyDashboardFilters,
  MyDashboardInfo,
  MyDashboardInfoColumn,
  MyDashboardInfoColumnHeading,
  MyDashboardInfoTotal,
} from '../style'

const QUICK_DATE_RANGE_OPTIONS: RangeType[] = [
  {
    label: 'This month',
    value: [
      moment.utc().startOf('month').toDate(),
      moment.utc().endOf('month').toDate(),
    ],
  },
  {
    label: 'Last month',
    value: [
      moment.utc().subtract(1, 'month').startOf('month').toDate(),
      moment.utc().subtract(1, 'month').endOf('month').toDate(),
    ],
  },
  {
    label: 'Last 3 months',
    value: [
      moment.utc().subtract(3, 'month').startOf('month').toDate(),
      moment.utc().endOf('month').toDate(),
    ],
  },
  {
    label: 'All time',
    value: [
      moment.utc().subtract(10, 'year').startOf('month').toDate(),
      moment.utc().endOf('month').toDate(),
    ],
  },
]

const createStartingData = (): GraphAreaChartDataPoint[] => {
  const currentMonthInDays: GraphAreaChartDataPoint[] = []

  for (let i = 0; i < moment.utc().daysInMonth(); i++) {
    currentMonthInDays.push({
      label: moment.utc().startOf('month').add(i, 'day').format('MMM DD'),
      value: 0,
    })
  }

  return currentMonthInDays
}

const TotalWallets: FunctionComponent = () => {
  // Derive context.
  const { environment } = useEnvironment()
  const theme = useTheme()

  // Derive state.
  const [cumulative, setCumulative] = useState<boolean>(true)
  const [data, setData] = useState<GraphAreaChartDataPoint[]>(
    createStartingData(),
  )
  const [fetchingTotalWallets, setFetchingTotalWallets] =
    useState<boolean>(true)
  const [startsAt, setStartsAt] = useState<moment.Moment | null>(
    moment.utc().startOf('month'),
  )
  const [endsAt, setEndsAt] = useState<moment.Moment | null>(
    moment.utc().endOf('month'),
  )
  const [totalWallets, setTotalWallets] = useState<number | null>(null)

  const fetchTotalWallets = async () => {
    if (!environment) return

    setFetchingTotalWallets(true)

    try {
      // Derive granularity.
      let granularity = 'day'
      if (startsAt && endsAt) {
        const days = endsAt.diff(startsAt, 'days')
        if (days > 30) granularity = 'week'
        if (days > 365) granularity = 'month'
        if (days > 365 * 5) granularity = 'year'
      }

      // Attempt to make the api request.
      const response: InsightsResponse =
        await api.custodian.environments.insights.getTotalWallets(
          environment.id,
          {
            cumulative,
            endsAtMS: endsAt?.valueOf(),
            granularity,
            startsAtMS: startsAt?.valueOf(),
          },
        )

      // Update the data in state.
      setTotalWallets(response.total)
      setData(response.data)
    } catch (error) {
      console.log('Error fetching total wallets', error)
      Sentry.captureException(error)
    }

    setFetchingTotalWallets(false)
  }

  useEffect(() => {
    void fetchTotalWallets()
  }, [environment, startsAt, endsAt, cumulative])

  const onDateRangeChange = (value: Date[]) => {
    if (!value) {
      // If the value is null, set the date range to the current month.
      setStartsAt(moment.utc().startOf('month'))
      setEndsAt(moment.utc().endOf('month'))
      return
    }

    // Update the date range in state.
    const [startsAt, endsAt] = value
    setStartsAt(moment.utc(startsAt))
    setEndsAt(moment.utc(endsAt))
  }

  const startsAtDate = startsAt?.toDate()
  const endsAtDate = endsAt?.toDate()

  const startsAtUTCDate = deriveUTCDate(startsAtDate)
  const endsAtUTCDate = deriveUTCDate(endsAtDate)

  return (
    <>
      <MyDashboardInfo>
        <MyDashboardInfoColumn>
          <MyDashboardInfoColumnHeading id="graph-wallets-total-title">
            Wallets Created
          </MyDashboardInfoColumnHeading>
          <MyDashboardInfoTotal id="graph-wallets-total-value">
            {fetchingTotalWallets
              ? '--'
              : `${totalWallets?.toLocaleString() || 0}`}
          </MyDashboardInfoTotal>
        </MyDashboardInfoColumn>
        <MyDashboardFilters>
          <Dropdown
            id="graph-wallets-total-filters-cumulative"
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              // Update the cumulative state.
              setCumulative(event.target.value === 'true')
            }}
            options={[
              { label: 'Cumulative', value: 'true' },
              { label: 'Not cumulative', value: 'false' },
            ]}
            value={cumulative.toString()}
          />
          <DateRangePicker
            character=" - "
            defaultCalendarValue={[startsAtUTCDate, endsAtUTCDate]}
            format="MMM dd, yyyy"
            id="graph-wallets-total-filters-date-range"
            onChange={onDateRangeChange}
            placement="auto"
            ranges={QUICK_DATE_RANGE_OPTIONS}
            value={[startsAtUTCDate, endsAtUTCDate]}
          />
        </MyDashboardFilters>
      </MyDashboardInfo>
      <MyDashboardChartContainer>
        {fetchingTotalWallets && <LoadingChart />}
        <ResponsiveContainer id="graph-wallets-total" height={275} width="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={
                    theme.isDark ? theme.colors.blue : theme.colors.primary
                  }
                  stopOpacity={0.1}
                />
                <stop
                  offset={theme.isDark ? '75%' : '95%'}
                  stopColor={
                    theme.isDark ? theme.colors.black : theme.colors.white
                  }
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <XAxis
              axisLine={false}
              dataKey="label"
              tickLine={false}
              tickMargin={15}
            />
            <YAxis
              axisLine={false}
              tickFormatter={(value) => {
                const isDecimal = value % 1 !== 0
                return isDecimal ? '' : (value as number).toLocaleString()
              }}
              tickLine={false}
              tickMargin={15}
            />
            <Tooltip
              content={GraphTooltip}
              wrapperStyle={{ border: 'none', outline: 'none' }}
            />
            <Area
              activeDot={{ r: 6 }}
              dataKey="value"
              fill="url(#colorUv)"
              fillOpacity={1}
              stroke={theme.isDark ? theme.colors.blue : theme.colors.primary}
              strokeWidth={2}
              type="linear"
            />
          </AreaChart>
        </ResponsiveContainer>
      </MyDashboardChartContainer>
    </>
  )
}

export default TotalWallets
